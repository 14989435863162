// @flow
import React, {useState} from 'react';

// Components
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
type Props = {
    inputText: string,
    setInputText: function,
};

const contentStateConverter = (contentState) => {
    // changes block type of images to 'atomic'
    const newBlockMap = contentState.getBlockMap().map((block) => {
        const entityKey = block.getEntityAt(0);
        if (entityKey !== null) {
            const entityBlock = contentState.getEntity(entityKey);
            const entityType = entityBlock.getType();
            switch (entityType) {
                case 'IMAGE': {
                    const newBlock = block.merge({
                        type: 'atomic',
                        text: 'img',
                    });
                    return newBlock;
                }
                default:
                    return block;
            }
        }
        return block;
    });

    return contentState.set('blockMap', newBlockMap);
};

// Hooks version of the Class below (done by me)
export const WYSIWYGEditor = ({inputText = '', setInputText}: Props): any => {
    const blocksFromHTML = convertFromHTML(inputText);
    const contentDataState = ContentState.createFromBlockArray(
        // $FlowIgnore
        blocksFromHTML.contentBlocks,
        // $FlowIgnore
        blocksFromHTML.entityMap
    );
    const editorDataState = EditorState.createWithContent(
        contentStateConverter(contentDataState)
    );

    const [editorState, setEditorState] = useState(editorDataState);
    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        setInputText(
            draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
    };

    return (
        <React.Fragment>
            <div className="editor pp">
                <Editor
                    editorState={editorState}
                    wrapperClassName="wyswyg_wrapper_class"
                    editorClassName="wyswyg_editor_class"
                    toolbarClassName="wyswyg_toolbar_class"
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
        </React.Fragment>
    );
};
