// @flow
import React from 'react';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';

type MobileWrapperProps = {
    children?: React$Element<any>,
    [index: string]: any,
};

/**
 * Wraps it's children with a div with it's properties if we're on a small device
 *
 * @param {Object} props
 * @return {React$Element<any>}
 */
export const MobileWrapper = ({
    children,
    ...props
}: MobileWrapperProps): React$Element<any> => {
    if (!isDeviceSmall()) {
        return children || <></>;
    }

    if (!children) {
        return <></>;
    }

    if (Object.keys(props).length == 0) {
        props.className = "mobile-buttons";
    }

    return <div {...props}>{children}</div>;
};
