import React, {useCallback} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useConfirmationDialog} from 'shared';
import {CBCButton} from 'shared/helpers';

interface CancelButtonProps {
    to: string;
}

export const CancelButton = ({to}: CancelButtonProps) => {
    const {jobId} = useParams();
    const navigate = useNavigate();
    const {dialog, showDialog} = useConfirmationDialog();

    const handleCancelClick = useCallback(() => {
        showDialog({
            title: 'Are you sure you want to exit?',
            message: 'Your progress so far has not been saved',
            yes: () => {
                const url = to.replace(':jobId', jobId);

                if (jobId) navigate(url);
                else navigate(`/v2`);
            },
        });
    }, [to]);

    return (
        <>
            <CBCButton
                onClick={handleCancelClick}
                iconName="Button-Cancel.svg"
                className="job-button button-light"
                style={{color: '#8a8080'}}>
                Cancel
            </CBCButton>
            {dialog}
        </>
    );
};
