import React, {useCallback, useState} from 'react';
import {Image} from 'react-bootstrap';
import type {ItemType} from 'shared/components/Product/Item';
import {ItemColumn} from 'shared/components/Product/ItemColumn';
import {
    DragProps,
    withDragTarget,
} from 'shared/components/DragAndDrop/withDragTarget';

interface SettingsItemProps extends DragProps {
    index?: number;
    item: ItemType;
    containerLength: number;
}

const SettingItemComponent = ({
    item,
    containerLength,
    isDragging,
    dragRef,
}: SettingsItemProps) => {
    const [imageLoading, setImageLoading] = useState(true);

    const resetImageLoading = useCallback(() => {
        setImageLoading(false);
    }, [setImageLoading]);

    const opacity = isDragging ? 0.5 : 1;

    return (
        <ItemColumn
            ref={dragRef}
            style={{opacity}}
            $container={containerLength}>
            {!item.hidden ? (
                <div className="hardwareItem">
                    <div className="imageContainer">
                        {imageLoading ? (
                            <div className="animated-background-for-image" />
                        ) : null}

                        {item.imageUrl ? (
                            <Image
                                src={
                                    item.changedImage
                                        ? `/${item.changedImage}`
                                        : `/${item.imageUrl}`
                                }
                                alt={item.name}
                                onError={resetImageLoading}
                                onLoad={resetImageLoading}
                            />
                        ) : null}
                    </div>
                    <div className="item-name">{item.name}</div>
                </div>
            ) : null}
        </ItemColumn>
    );
};

export const SettingItem = withDragTarget<ItemType, SettingsItemProps>(
    SettingItemComponent
);
