// @flow
import React, {useState, useMemo, useRef} from 'react';
import {Button} from 'react-bootstrap';
import {Icon} from './helpers';

import {ConfirmationDialog} from 'shared';

export const DIRTY_PAGE = {
    title: 'Changes not yet saved',
    message:
        '<p>Are you sure you want to leave? There are item/s in your cart which have had the quantity changed but have not been saved. If you proceed, these changes will be lost.</p>',
};

type PromptButtonType = {
    iconName: string,
    action: Function,
    showConfirmation: boolean,
    className?: string,
    disabled?: boolean,
    children?: React$Element<any>,
};

export const PromptButton = ({
    iconName,
    action,
    showConfirmation,
    className,
    disabled = false,
    children,
}: PromptButtonType): React$Element<any> => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const ogEvent = useRef<Object>();

    const clickHandler = (e) => {
        e.persist();
        ogEvent.current = e;
        if (showConfirmation) {
            setShowModal(true);
        } else {
            action && action(e);
        }
    };

    const options = useMemo(
        () => ({
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Proceed',
                    show: true,
                    variant: 'danger',
                    action: () => {
                        action && action(ogEvent.current);
                    },
                },
                {
                    name: 'Cancel',
                    show: true,
                    action: () => {
                        setShowModal(false);
                    },
                },
            ],
        }),
        [action]
    );

    return (
        <>
            <Button
                variant="link"
                disabled={disabled}
                onClick={clickHandler}
                className={className}>
                <Icon iconName={iconName} />
                {children ? <span> {children} </span> : <></>}
            </Button>
            <ConfirmationDialog
                title={DIRTY_PAGE.title}
                message={DIRTY_PAGE.message}
                show={showModal}
                options={options}
                hideDialog={() => setShowModal(false)}
                backdrop="static"
            />
        </>
    );
};
