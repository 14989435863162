import React, {useMemo} from 'react';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {useConfirmationDialog} from 'shared';
import {
    formatPrice,
    genericMessageHandler,
    useQueryParams,
} from 'shared/helpers';
import {PartialJob} from 'shared/types/PartialJob';
import type {OptionsType, TableButton} from 'shared/types';
import {SidebarProduct} from 'shared/components/Header/entity/SidebarProduct';
import {useFetchSidebarProducts} from 'shared/components/Header/helper/useFetchSidebarProducts';
import {
    invalidateProductAndCost,
    useDeleteCabinetMutation,
} from 'components/customer/Product/store/productApi';
import {useAppDispatch} from 'store/customer';

export const useProductTableConfig = () => {
    const {notify} = useNotificationContext();
    const {
        job,
        room,
        refresh,
        isPageDirty,
        setIsPageDirty,
        actionButtonsEnabled,
    } = useJobContext() as PartialJob;
    const {showDialog, dialog} = useConfirmationDialog();
    const {cabinetId} = useQueryParams() as {cabinetId: number};
    const {userProfile} = useAppContext();
    const navigate = useNavigate();
    const addProductUrl = `/v2/job/${job.displayId}/room/${room.id}/product`;
    const addRoomUrl = `/v2/job/${job.displayId}/room`;
    const {products, isFetching} = useFetchSidebarProducts();
    const [deleteProduct] = useDeleteCabinetMutation();
    const dispatch = useAppDispatch();

    const removeCabinet = (
        jobCabinet: SidebarProduct,
        event: React.MouseEvent<HTMLElement>
    ) => {
        const button = event.currentTarget.parentElement as HTMLButtonElement;
        showDialog({
            title: 'Delete item',
            message: 'Are you sure you want to delete this item from the job?',
            yes: async () => {
                try {
                    button.disabled = true;
                    await deleteProduct({cabinetId: jobCabinet.id});
                    dispatch(invalidateProductAndCost());

                    refresh({reloadFromServer: true});
                    setIsPageDirty([]);

                    button.disabled = false;
                    genericMessageHandler(
                        notify,
                        {message: 'Item removed.'},
                        'success'
                    );

                    if (cabinetId && cabinetId == jobCabinet.id) {
                        navigate(`/v2/job/${job.displayId}/dashboard`);
                    }
                } catch (e) {
                    button.disabled = false;
                    genericMessageHandler(notify, e);
                }
            },
        });
    };

    const editCabinet = (
        row: SidebarProduct,
        copy = false,
        isQFP = false,
        event: React.MouseEvent<HTMLElement>
    ) => {
        event.stopPropagation();
        let url = `/v2/job/${job.displayId}/room/${room.id}/product?product=${row.type}&cabinetId=${row.id}`;

        if (copy) {
            url += '&copy';
        }

        if (isQFP) {
            url = `/v2/job/${job.displayId}/room/${room.id}/quick-flat-product?edit=${row.id}`;
        }

        navigate(url);
    };

    const nameFormat = (value: string, row: SidebarProduct) => {
        if (cabinetId && cabinetId == row.id) {
            return (
                <strong>
                    {`${row.quantity} x ${row.note ? row.note : value}`}
                </strong>
            );
        }

        return `${row.quantity} x ${row.note ? row.note : value}`;
    };

    const dimensionFormat = (value: string, row: SidebarProduct) => {
        if (cabinetId && cabinetId == row.id) {
            return <strong>{`${row.dimensions}`}</strong>;
        }

        return `${row.dimensions}`;
    };

    const priceFormat = (value: number, row: SidebarProduct) => {
        if (cabinetId && cabinetId == row.id) {
            return <strong>{formatPrice(value, row)}</strong>;
        }

        return formatPrice(value, row);
    };

    const noRoomAndVariationProducts = (room: number, variations: number) => {
        if (!room && variations === 0) {
            return (
                <>
                    Please click{' '}
                    <span
                        style={{color: 'red', cursor: 'pointer'}}
                        onClick={() => navigate(addRoomUrl)}>
                        <strong>Add A Room </strong>
                    </span>{' '}
                    before adding products.
                </>
            );
        } else if (room && variations > 0) {
            return <>No Products.</>;
        } else {
            return (
                <>
                    No Products.{' '}
                    {job.displayId && (
                        <span
                            style={{color: 'red', cursor: 'pointer'}}
                            onClick={() => navigate(addProductUrl)}>
                            <strong>Click Add Product</strong> to add one.
                        </span>
                    )}
                </>
            );
        }
    };

    const tableConfig = useMemo(() => {
        if (room) {
            const config: OptionsType<SidebarProduct> = {
                fields: [
                    {
                        fieldName: 'productNumber',
                        title: '#',
                        format: function formatProductNumber(
                            productNumber: string
                        ) {
                            return (
                                <strong className="productNumbers">
                                    {productNumber}
                                </strong>
                            );
                        },
                    },
                    {
                        fieldName: 'typeName',
                        title: 'Current Products',
                        format: nameFormat,
                    },
                    {
                        fieldName: 'dimensionString',
                        title: 'Dimensions',
                        format: dimensionFormat,
                        width: 80,
                    },
                    userProfile.show_pricing
                        ? {
                              fieldName: 'cost',
                              title: 'Cost',
                              width: 65,
                              format: function productFormatPrice(
                                  value: number,
                                  row
                              ) {
                                  return (
                                      <div
                                          style={
                                              job.status == 5
                                                  ? {textAlign: 'center'}
                                                  : {}
                                          }>
                                          {priceFormat(value, row)}
                                      </div>
                                  );
                              },
                          }
                        : {},
                ],
                data: isFetching ? undefined : products,
                noRecordsText: (
                    <>
                        {noRoomAndVariationProducts(
                            room.id,
                            job.variationsConfirmed
                        )}
                    </>
                ),
            };

            if (job.status != 5) {
                let buttons: TableButton<SidebarProduct>[] = [];
                if (
                    !userProfile.isAddProductAvailable &&
                    userProfile.isQFPAvailable
                ) {
                    buttons = [
                        {
                            iconName: 'Options-Edit.svg',
                            title: 'Edit Product',
                            action: (row) => {
                                const url = `/v2/job/${job.displayId}/room/${room.id}/quick-flat-product?edit=${row.id}`;
                                navigate(url);
                            },
                        },
                        {
                            iconName: 'Options-Delete.svg',
                            title: 'Delete Product',
                            action: removeCabinet,
                            confirmChanges: isPageDirty.length > 0,
                        },
                    ];
                } else if (userProfile.isAddProductAvailable) {
                    buttons = [
                        {
                            iconName: 'Options-Edit.svg',
                            title: 'Edit Product',
                            action: (row, event) =>
                                editCabinet(row, false, row.isQFP, event),
                        },
                        {
                            iconName: 'Options-Copy.svg',
                            title: 'Copy Product',
                            action: (row, event) => {
                                editCabinet(row, true, false, event);
                            },
                            isHidden: (row) => row.isQFP,
                        },
                        {
                            iconName: 'Options-Delete.svg',
                            title: 'Delete Product',
                            action: removeCabinet,
                            confirmChanges: isPageDirty.length > 0,
                        },
                    ];
                }

                config.options = {
                    title: '',
                    width: 75,
                    buttons,
                };
            }

            if (!actionButtonsEnabled) {
                config.options = undefined;
            }

            return config;
        } else {
            return undefined;
        }
    }, [
        products,
        room,
        job,
        userProfile,
        isPageDirty,
        actionButtonsEnabled,
        cabinetId,
        navigate,
    ]);

    return {
        tableConfig,
        dialog,
        isFetching,
    };
};
