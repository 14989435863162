import React, {useEffect} from 'react';
import {formatPrice} from 'shared/helpers';
import {TableRow, TableCell} from './VariationCostMoreInfo';
import {useLazyGetExtraVariationsQuery} from 'components/customer/Job/store/jobApi';

const JobExtraVariationCostMoreInfo = ({jobId}: {jobId: number}) => {
    const [getExtraVariations, {data: extraVariationList}] =
        useLazyGetExtraVariationsQuery();

    useEffect(() => {
        if (jobId) {
            void getExtraVariations({jobId: jobId});
        }
    }, [jobId]);

    return extraVariationList && extraVariationList.length > 0 ? (
        <>
            {extraVariationList.map((item) => (
                <TableRow key={item.id}>
                    <TableCell>
                        <strong>Custom Variations:</strong>
                    </TableCell>
                    <TableCell style={{color: 'rgb(255, 0, 0)'}}>
                        {item.name}
                    </TableCell>
                    <TableCell $align="right">
                        {formatPrice(item.cost, {})}
                    </TableCell>
                </TableRow>
            ))}
        </>
    ) : null;
};

export default JobExtraVariationCostMoreInfo;
